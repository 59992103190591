export const screenStates = {
  GET_PHONE: 'GET_PHONE',
  CODE_APPROVE: 'CODE_APPROVE',
  CREATE_NEW_PASSWORD: 'CREATE_NEW_PASSWORD',
  PASSPORT: 'PASSPORT',
  CREATE_NEW_PHONE: 'CREATE_NEW_PHONE',
  NEW_PHONE_CODE_APPROVE: 'NEW_PHONE_CODE_APPROVE',
  CREATE_PASSWORD: 'CREATE_PASSWORD',
};

export const requestCode = {
  OK: 'OK',
  SMS_CODE_SENDED: 'SMS_CODE_SENDED',
  ERR_PHONE_EMPTY: 'ERR_PHONE_EMPTY',
  ERR_CODE_NOT_EXPIRED: 'ERR_CODE_NOT_EXPIRED',
  ERR_CODE_EXPIRED: 'ERR_CODE_EXPIRED',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  SERVICE_TEM_UNA: 'SERVICE_TEM_UNA',
  PASSWORD_CHANGED: 'PASSWORD_CHANGED',
  ERR_WRONG_LOGIN: 'ERR_WRONG_LOGIN',
  ERR_WRONG_OLD_LOGIN2: 'ERR_WRONG_OLD_LOGIN2',
  ERR_WRONG_PASSWORD: 'ERR_WRONG_PASSWORD',
  ERROR_FLATS_DOWNLOAD: 'ERROR_FLATS_DOWNLOAD',
  ERR_USER_NOT_FOUND: 'ERR_USER_NOT_FOUND',
  ERR_WRONG_CAPTCHA: 'ERR_WRONG_CAPTCHA',
  ERROR_DEFAULT: 'ERROR_DEFAULT',
};

export const requestCodeText = {
  ERR_PHONE_EMPTY: 'Неверный код верификации',
  ERR_CODE_EXPIRED: 'Срок действия кода истёк',
  USER_NOT_FOUND: 'Пользователь не найден',
  SERVICE_TEM_UNA: 'Сервис временно недоступен',
  ERR_WRONG_CODE: 'Неверный код',
  ERR_SMS_CODE_EXPIRED: 'Срок действия кода истек',
  ERR_CODE_NOT_EXPIRED: 'Предыдущий код еще не истек',
  ERR_CUSTOMER_NOT_FOUND: 'Пользователь не найден',
  ERR_WRONG_LOGIN: 'Неверный логин или пароль',
  ERR_WRONG_OLD_LOGIN2: 'Неверный логин или пароль',
  ERR_WRONG_PASSWORD: 'Неверный логин или пароль',
  ERROR_FLATS_DOWNLOAD: 'Вход временно недоступен, повторите попытку позже',
  ERR_USER_NOT_FOUND: 'Пользователь не найден',
  ERR_WRONG_CAPTCHA: 'Сервис временно недоступен, обратитесь в службу поддержки',
  ERROR_DEFAULT: 'Сервис временно недоступен, обратитесь в службу поддержки',
};
