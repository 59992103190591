import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import NextHead from 'next/head';
import TagManager from 'react-gtm-module';

import { head } from '@pik/pik-utils';

const { constants, createFaviconLink, checkDarkMode } = head;

const isProd = process.env.NODE_ENV === 'production';

const renderTag = (i, data, isLink, isDark) => {
  const key = `${Object.values(data)[0]}_${i}_${Number(isDark)}`;
  const { createUrl, href, content } = data;
  let newData = data;

  if (isLink) {
    if (createUrl) {
      newData = {
        ...data,
        href: createFaviconLink(href, isDark),
      };
    }

    return (
      <link
        key={key}
        {...newData}
      />
    );
  }

  if (createUrl) {
    newData = {
      ...data,
      content: createFaviconLink(content, isDark),
    };
  }

  return (
    <meta
      key={key}
      {...newData}
    />
  );
};

const renderNoScript = (noscript) => {
  if (noscript) return <noscript key={noscript}>{noscript}</noscript>;
  return null;
};

const renderScriptSrc = (scriptSrc) => {
  if (scriptSrc) return <script key={scriptSrc} src={scriptSrc} async />;
  return null;
};

const renderCounter = (counter) => {
  const { script, noscript, scriptSrc } = counter;

  if (isProd) {
    return (
      <>
        <script dangerouslySetInnerHTML={{ __html: script }} />
        {renderNoScript(noscript)}
        {renderScriptSrc(scriptSrc)}
      </>
    );
  }

  return null;
};

const Head = ({
  data: {
    title, description, keywords, ogImage,
  },
}) => {
  const [isDark, darkHandler] = useState(false);

  useEffect(() => {
    darkHandler(checkDarkMode());
    if (isProd) {
      TagManager.initialize({ gtmId: constants.GTM_ID });
    }
  }, []);

  return (
    <NextHead>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
      {ogImage && <meta name="og:image" content={ogImage} />}
      {constants.meta.map((data, i) => renderTag(i, data, false, isDark))}
      {/*{constants.link.map((data, i) => renderTag(i, data, true, isDark))}*/}
      {constants.counters.map((counter) => renderCounter(counter, isDark))}
    </NextHead>
  );
};

Head.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    keywords: PropTypes.string.isRequired,
    ogImage: PropTypes.string,
  }).isRequired,
};

export default Head;
