export const actionTypes = {
  SEND_AUTH: 'SEND_AUTH',
  SEND_AUTH_REQUEST: 'SEND_AUTH_REQUEST',
  SEND_AUTH_SUCCESS: 'SEND_AUTH_SUCCESS',
  SEND_AUTH_ERROR: 'SEND_AUTH_ERROR',
};

export const sendAuth = (data) => ({ type: actionTypes.SEND_AUTH, data });

export const sendAuthRequest = () => ({ type: actionTypes.SEND_AUTH_REQUEST });

export const sendAuthSuccess = () => ({ type: actionTypes.SEND_AUTH_SUCCESS });

export const sendAuthError = (error) => ({ type: actionTypes.SEND_AUTH_ERROR, error });
